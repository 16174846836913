import React, { useState } from "react";

import { navigate } from "gatsby";
import { useForm, useWatch } from "react-hook-form";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import { errorAwareApiFetch } from "../../../../apiBindings";
import pushToAnalytics from "../../../../utils/analytics";
import Container from "../../../shared/Container";
import NewCheckbox from "../../../shared/forms/NewCheckbox";
import Input from "../../../shared/forms/NewInput";
import NewSelect from "../../../shared/forms/NewSelect";
import ReactHookInput from "../../../shared/forms/ReactHookInput";
import ReactHookSelect from "../../../shared/forms/ReactHookSelect";
import { PredefinedIconType } from "../../../shared/icons/Icon/Predefined";
import LinkComponent from "../../../shared/LinkComponent";
import Button from "../../../shared/NewForms/Button";
import Headline from "../../../shared/NewHeadline";
import useSnackbar from "../../../shared/Snackbar/store";
import { Day, Time } from "../../TechnicShop/store";
import useStore, { iProduct } from "../store";

export const FormFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: start;
    width: 100%;
`;

const Text = styled.div`
    font-size: small;
    color: ${({ theme }) => theme.colors.blue.toString()};
`;

type iContactForm = {
    company: string;
    salutation: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    product: iProduct | null;
    day: Day;
    time: Time;
    text: string;
    isPrivacyPolicyAccepted: boolean;
};

const ContactPage = () => {
    const [savedProduct, category, page, search] = useStore(
        (state) => [
            state.savedProduct,
            state.category,
            state.page,
            state.search,
        ],
        shallow
    );

    const [
        hasErrorIsPrivacyPolicyAccepted,
        setHasErrorIsPrivacyPolicyAccepted,
    ] = useState<boolean>(false);
    const { notify } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { handleSubmit, control, setValue } = useForm<iContactForm>({
        defaultValues: {
            company: "",
            salutation: "Herr",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            product: savedProduct,
            day: Day.none,
            time: Time.none,
            text: "",
            isPrivacyPolicyAccepted: false,
        },
        mode: "onSubmit",
    });

    const day = useWatch({
        control,
        name: `day`,
    });

    const time = useWatch({
        control,
        name: `time`,
    });

    const text = useWatch({
        control,
        name: "text",
    });

    const isPrivacyPolicyAccepted = useWatch({
        control,
        name: "isPrivacyPolicyAccepted",
    });

    const submit = async (data: iContactForm) => {
        if (!isPrivacyPolicyAccepted) {
            setHasErrorIsPrivacyPolicyAccepted(true);
            return;
        }
        setIsLoading(true);
        errorAwareApiFetch("/oil-finder/contact", {
            method: "POST",
            body: JSON.stringify({
                ...data,
                day: Day[data.day] ?? Day["none"],
                time: Time[data.time] ?? Time["none"],
            }),
        })
            .then((response) => {
                pushToAnalytics("Anfrageformular Schmierstoffe", {
                    item: savedProduct.name,
                });
                notify(
                    "Erfolgreich",
                    "Deine Anfrage ist erfolgreich bei uns eingegangen."
                );

                navigate(
                    `/schmierstoffe/produkte?page=${page}${
                        category ? `&category=${category?.id}` : ""
                    }${search ? `&search=${search}` : ""}`
                );
            })
            .catch((errors) => {
                notify(
                    "Fehler",
                    "Leider ist ein Fehler bei deiner Anfrage aufgetreten.",
                    "error"
                );
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div>
            <Container>
                <LinkComponent
                    css={css`
                        cursor: pointer;
                    `}
                    goBack
                    color="red"
                    link={{
                        text: "Zurück",
                        href: `/schmierstoffe/produkte?page=${page}${
                            category ? `&category=${category?.id}` : ""
                        }${search ? `&search=${search}` : ""}`,
                    }}
                    icon={PredefinedIconType.arrowLeft}
                    leftIcon
                />
                <Container ultraSlim>
                    <FormFlex>
                        <Headline>Kontaktdaten</Headline>
                        <Row>
                            <ReactHookInput
                                placeholder="Firma"
                                name="company"
                                rules={{
                                    required: true,
                                    maxLength: 40,
                                }}
                                control={control}
                                fullWidth
                            />
                        </Row>
                        <Row>
                            <ReactHookSelect
                                placeholder="Anrede"
                                name="salutation"
                                rules={{
                                    required: true,
                                    maxLength: 6,
                                }}
                                control={control}
                                fullWidth
                                options={[
                                    { text: "Herr", value: "Herr" },
                                    { text: "Frau", value: "Frau" },
                                    { text: "Divers", value: "Divers" },
                                ]}
                            />
                        </Row>
                        <Row>
                            <ReactHookInput
                                placeholder="Vorname"
                                name="firstname"
                                rules={{
                                    required: true,
                                    maxLength: 40,
                                }}
                                control={control}
                                fullWidth
                            />
                        </Row>
                        <Row>
                            <ReactHookInput
                                placeholder="Nachname"
                                name="lastname"
                                rules={{
                                    required: true,
                                    maxLength: 40,
                                }}
                                control={control}
                                fullWidth
                            />
                        </Row>
                        <Row>
                            <ReactHookInput
                                placeholder="E-Mail"
                                name="email"
                                rules={{
                                    required: true,
                                    maxLength: 40,
                                }}
                                control={control}
                                fullWidth
                            />
                        </Row>
                        <Row>
                            <ReactHookInput
                                placeholder="Telefonnummer"
                                name="phone"
                                rules={{
                                    required: true,
                                    maxLength: 40,
                                }}
                                control={control}
                                fullWidth
                            />
                        </Row>
                        <Headline>Rückrufwunsch</Headline>
                        <Row>
                            <NewSelect
                                value={day}
                                onChange={(event) => {
                                    setValue("day", event.target.value);
                                }}
                                placeholder="Wochen Mo-Fr"
                                name="day"
                                options={Object.keys(Day).map((key) => {
                                    return {
                                        text: Day[key],
                                        value: key,
                                    };
                                })}
                                fullwidth
                            />
                        </Row>
                        <Row>
                            <NewSelect
                                value={time}
                                onChange={(event) => {
                                    setValue("time", event.target.value);
                                }}
                                placeholder="Zeitraum"
                                name="time"
                                options={Object.keys(Time).map((key) => {
                                    return {
                                        text: Time[key],
                                        value: key as Time,
                                    };
                                })}
                                fullwidth
                            />
                        </Row>
                        <Headline>Anmerkung</Headline>
                        <Text>
                            Bitte gib Deine Kundennummer an, sofern zur Hand.
                        </Text>
                        <Row>
                            <Input
                                fullWidth
                                type="textarea"
                                name="text"
                                value={text}
                                onChange={(event) =>
                                    setValue("text", event.target.value)
                                }
                            />
                        </Row>
                        <Row>
                            <NewCheckbox
                                required
                                onChange={() =>
                                    setValue(
                                        "isPrivacyPolicyAccepted",
                                        !isPrivacyPolicyAccepted
                                    )
                                }
                                checked={isPrivacyPolicyAccepted}
                                name="isPrivacyPolicyAccepted"
                                validation="isPrivacyPolicyAccepted"
                                forceError={hasErrorIsPrivacyPolicyAccepted}
                                onErrorSolved={() =>
                                    setHasErrorIsPrivacyPolicyAccepted(false)
                                }
                            >
                                Ich akzeptiere die{" "}
                                <a href="/datenschutz" target="_blank">
                                    Datenschutzerklärung.
                                </a>
                            </NewCheckbox>
                        </Row>
                        <Row
                            css={css`
                                align-items: center;
                                justify-content: space-between;
                            `}
                        >
                            <LinkComponent
                                button
                                link={{
                                    href: "/schmierstoffe/produkte",
                                    text: "Zurück",
                                }}
                            />
                            <Button
                                onClick={handleSubmit(submit)}
                                loading={isLoading}
                            >
                                Anfrage senden
                            </Button>
                        </Row>
                    </FormFlex>
                </Container>
            </Container>
        </div>
    );
};

export default ContactPage;
