import React, { FC, FormEvent, FormEventHandler, useMemo } from "react";

import { Form } from "react-bulma-components";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import cls from "../../../../utils/cls";
import { activeLink } from "../../../shared/activeLink";
import NewSelect from "../../../shared/forms/NewSelect";
import Icon from "../../../shared/icons/Icon";
import SearchInput from "../../../shared/maps/SearchInput";
import Button from "../../../shared/NewForms/Button";
import useGasStations from "../store";
import * as asyncData from "../types/AsyncData";

const Tabs = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
`;

const Tab = styled.div`
    all: unset;
    font-family: var(--font-family-vito);
    font-weight: 900;
    display: flex;
    align-items: center;
    font-size: 1em;
    ${({ theme }) => theme.breakpoints.from.medium.css`
        font-size: 1.5em;
    `}
    ${activeLink}
`;

const circumscribedAreaStyle = css`
    margin-top: 20px;
`;

export type FinderFormProps = {
    onSubmit: FormEventHandler<HTMLFormElement>;
} & JSX.IntrinsicElements["div"];

const FinderForm: FC<FinderFormProps> = ({ onSubmit, ...props }) => {
    const {
        gasStations,
        filter,
        geolocation,
        newLocation,
        newRouteEnd,
        isSelectingRoute,
        setLocation,
        setLocationFromPlace,
        setRouteEnd,
        setRouteEndFromPlace,
        setFilterCircumscribedAreaRadius,
        setMapCenterFromGeolocation,
        startSelectingRoute,
        stopSelectingRoute,
    } = useGasStations((state) => ({
        availableOptions: state.availableOptions,
        popularOptions: state.popularOptions,
        gasStations: state.gasStations,
        filter: state.filter,
        geolocation: state.geolocation,
        newLocation: state.newLocation,
        newRouteEnd: state.newRouteEnd,
        isSelectingRoute: state.isSelectingRoute,
        setLocation: state.setLocation,
        setLocationFromPlace: state.setLocationFromPlace,
        setRouteEnd: state.setRouteEnd,
        setRouteEndFromPlace: state.setRouteEndFromPlace,
        setFilterFuelTypes: state.setFilterFuelTypes,
        setFilterProperties: state.setFilterProperties,
        setFilterStationTypes: state.setFilterStationTypes,
        setFilterCircumscribedAreaRadius:
            state.setFilterCircumscribedAreaRadius,
        resetFilter: state.resetFilter,
        setMapCenterFromGeolocation: state.setMapCenterFromGeolocation,
        startSelectingRoute: state.startSelectingRoute,
        stopSelectingRoute: state.stopSelectingRoute,
    }));

    const searchBounds = useMemo(() => {
        if (!asyncData.isLoaded(gasStations)) {
            return undefined;
        }

        const { lat, lng } = gasStations.data.reduce(
            (agg, { geo: { lat, lng } }) => ({
                lat: [Math.min(agg.lat[0], lat), Math.max(agg.lat[1], lat)],
                lng: [Math.min(agg.lng[0], lng), Math.max(agg.lng[1], lng)],
            }),
            {
                lat: [Infinity, -Infinity],
                lng: [Infinity, -Infinity],
            }
        );

        if (
            [lat[0], lng[0]].includes(Infinity) ||
            [lat[1], lng[1]].includes(-Infinity)
        ) {
            return undefined;
        }

        return {
            north: lat[1],
            east: lng[1],
            south: lat[0],
            west: lng[0],
        };
    }, [gasStations]);

    const circumscribedAreaValues = useMemo(
        () => [
            // Using "" as value disables the select
            { value: "null", text: "Kein Umkreis" },
            ...[10, 25, 50, 75, 100].map(String).map((value) => ({
                value,
                text: `${value} km`,
            })),
        ],
        []
    );
    const circumscribedAreaValue = circumscribedAreaValues.find(
        ({ value }) =>
            value ===
            (filter.circumscribedArea.radius
                ? String(filter.circumscribedArea.radius.value)
                : "null")
    );

    return (
        <div {...props}>
            <Tabs>
                <Tab
                    className={cls(!isSelectingRoute && "active")}
                    onClick={stopSelectingRoute}
                >
                    Standort
                </Tab>
                <Tab
                    className={cls(isSelectingRoute && "active")}
                    onClick={startSelectingRoute}
                >
                    Route
                </Tab>
            </Tabs>

            <Form.Field
                renderAs="form"
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    onSubmit(e);
                }}
            >
                <div
                    css={css`
                        display: flex;
                    `}
                >
                    <SearchInput
                        id="finder-search-input"
                        bounds={searchBounds}
                        aria-label={`${
                            isSelectingRoute ? "Start: " : ""
                        }Postleitzahl oder Ort`}
                        placeholder={`${
                            isSelectingRoute ? "Start: " : ""
                        }Postleitzahl oder Ort`}
                        aria-placeholder="21337"
                        value={newLocation.address ?? ""}
                        onChange={(evt) => setLocation(evt.target.value)}
                        onPlacesChange={(places) =>
                            places?.length && setLocationFromPlace(places[0])
                        }
                        name="location"
                        css={css`
                            flex-grow: 1;
                        `}
                    />
                    <Button
                        type="button"
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 50px !important;
                            height: 42px;
                            padding: 0 !important;
                            margin-top: 5px;
                            margin-left: 5px;
                        `}
                        data-gtag="gasStations:finder:getCurrentLocation"
                        onClick={() => setMapCenterFromGeolocation()}
                    >
                        <Icon iconName="location" />
                    </Button>
                </div>

                {isSelectingRoute ? (
                    <>
                        <Form.Field kind="group">
                            <Form.Control className="is-expanded">
                                <SearchInput
                                    id="finder-search-input"
                                    bounds={searchBounds}
                                    aria-label="Ziel: Postleitzahl oder Ort"
                                    placeholder="Ziel: Postleitzahl oder Ort"
                                    aria-placeholder="21339"
                                    value={newRouteEnd.address ?? ""}
                                    onChange={(event) =>
                                        setRouteEnd(event.target.value)
                                    }
                                    onPlacesChange={(places) =>
                                        places?.length &&
                                        setRouteEndFromPlace(places[0])
                                    }
                                    name="target"
                                />
                                {asyncData.isLoading(geolocation) && (
                                    <div
                                        className="is-sr-only"
                                        role="progressbar"
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        aria-valuetext="Ihr Aktueller Standort wird ermittelt"
                                    />
                                )}
                            </Form.Control>
                        </Form.Field>

                        <Button
                            type="submit"
                            className={`is-expanded`}
                            centered
                            data-gtag="gasStations:finder:findRoute"
                        >
                            Route berechnen
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            type="submit"
                            className={`is-expanded`}
                            centered
                            data-gtag="gasStations:finder:findGasStations"
                        >
                            Tankstelle finden
                        </Button>
                        <NewSelect
                            css={circumscribedAreaStyle}
                            placeholder="Umkreis"
                            value={circumscribedAreaValue?.value ?? "null"}
                            options={circumscribedAreaValues}
                            onChange={(e) =>
                                setFilterCircumscribedAreaRadius(
                                    e.target.value === "null"
                                        ? null
                                        : {
                                              value: Number(e.target.value),
                                              unit: "km",
                                          }
                                )
                            }
                        />
                    </>
                )}
            </Form.Field>
        </div>
    );
};
export default FinderForm;
